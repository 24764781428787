import { HeartIcon as SmallSolidHeartIcon } from "@heroicons/react/20/solid";
import Link from "next/link";
import React, { Fragment, ReactElement } from "react";

import styles from "./Footer.module.css";
import NewsletterForm from "./NewsletterForm";
import GitHubIcon from "./icons/GitHubIcon";
import TwitterIcon from "./icons/TwitterIcon";

type FooterLink = {
  href: string;
  text: string;
  target?: string;
};

type FooterLinksCol = {
  header: string;
  links: FooterLink[][];
};

const footerLinks: FooterLinksCol[] = [
  {
    header: "Product",
    links: [
      [
        { href: "/product", text: "Foxglove" },
        { href: "/mcap", text: "MCAP" },
      ],
      [
        { href: "/download", text: "Download" },
        { href: "/docs", text: "Documentation" },
        { href: "/tutorials", text: "Tutorials" },
        { href: "/ros", text: "ROS visualization" },
        { href: "/rosbridge", text: "Rosbridge" },
        { href: "/urdf", text: "URDF visualization" },
      ],
    ],
  },
  {
    header: "Get in touch",
    links: [
      [
        { href: "/community", text: "Community" },
        { href: "/newsletter", text: "Newsletter" },
        { href: "/demo", text: "Schedule a demo" },
        { href: "/contact", text: "Contact us" },
      ],
      [
        {
          href: "https://github.com/orgs/foxglove/discussions",
          text: "Discussions",
          target: "_blank",
        },
        { href: "/chat", text: "Discord" },
        { href: "https://twitter.com/foxglove", text: "Twitter", target: "_blank" },
      ],
    ],
  },
  {
    header: "Company",
    links: [
      [
        { href: "/about", text: "About" },
        { href: "/blog", text: "Blog" },
        { href: "/careers", text: "Careers" },
        { href: "/customers", text: "Customers" },
        { href: "/media", text: "Media" },
      ],
      [
        { href: "/security", text: "Security" },
        { href: "/legal/privacy", text: "Privacy" },
        { href: "/legal/terms", text: "Terms of Service" },
      ],
    ],
  },
  {
    header: "Developers",
    links: [
      [
        { href: "/docs/data-platform/api/introduction", text: "API" },
        { href: "https://foxglovestatus.com/", target: "_blank", text: "Status" },
        { href: "https://github.com/foxglove", target: "_blank", text: "GitHub " },
      ],
    ],
  },
];

export default function Footer(): ReactElement {
  return (
    <footer id="footer" className="bg-gray-50 relative z-10" aria-label="Footer">
      <div className={styles.wrapper ?? ""}>
        <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6">
          {footerLinks.map(({ header, links }, i) => (
            <div key={i} className={styles.links ?? ""}>
              <h3>{header}</h3>
              <ul>
                {links.map((linkList, j) => (
                  <Fragment key={j}>
                    {j > 0 && <hr />}
                    {linkList.map(({ href, text, target }, k) => (
                      <li key={k}>
                        <Link href={href} target={target}>
                          {text}
                        </Link>
                      </li>
                    ))}
                  </Fragment>
                ))}
              </ul>
            </div>
          ))}
          <div className="col-span-2">
            <h3>Subscribe to our newsletter</h3>
            <p className="mt-4 text-gray-500">
              Catch our latest news and features, sent directly to your inbox.
            </p>
            <NewsletterForm />
          </div>
        </div>
        <div className="mt-8 border-t border-gray-200 pt-4 md:flex md:items-center md:justify-between lg:mt-8">
          <div className="flex space-x-6 md:order-2">
            <a
              href="https://twitter.com/foxglove"
              target="_blank"
              rel="noopener"
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Twitter</span>
              <TwitterIcon className="h-6 w-6" />
            </a>
            <a
              href="https://github.com/foxglove"
              target="_blank"
              rel="noopener"
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">GitHub</span>
              <GitHubIcon className="h-6 w-6" />
            </a>
          </div>
          <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            Made with{" "}
            <SmallSolidHeartIcon className="h-5 w-5 inline-block align-text-top text-color-primary" />{" "}
            by <Link href="/about">Foxglove</Link>
          </p>
        </div>
      </div>
    </footer>
  );
}
