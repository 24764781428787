import axios from "axios";

type HubspotField = {
  objectTypeId: string;
  name: string;
  value?: string;
};

type HubspotContext = {
  pageUri?: string;
  pageName?: string;
};

export type HubspotLegalConsentOptions = {
  legitimateInterest: {
    value: boolean;
    subscriptionTypeId: number;
    legalBasis: "LEAD" | "CUSTOMER";
    text: string;
  };
};

export type HubspotResponse = {
  inlineMessage?: string;
  status?: string;
  message?: string;
  errors?: Array<{ message?: string; errorType?: string }>;
};

const PORTAL_ID = "21690402";

export async function submitForm(
  formId: string,
  body: {
    fields: HubspotField[];
    context?: HubspotContext;
    legalConsentOptions?: HubspotLegalConsentOptions;
  },
): Promise<HubspotResponse> {
  const hubspotUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${formId}`;
  const hubspotResponse = await axios.post(hubspotUrl, body);
  const data = hubspotResponse.data as HubspotResponse;
  if (hubspotResponse.status >= 300 || data.status === "error" || data.errors) {
    throw new Error(`Hubspot error (${hubspotResponse.status}):\n${JSON.stringify(data)}`);
  }

  console.log("Hubspot signup:", data);
  return data;
}
