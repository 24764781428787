import Head from "next/head";
import { useRouter } from "next/router";
import React, { ReactElement } from "react";

const BASE_URL = "https://foxglove.dev";

export type HeaderMetaProps = {
  pageTitle: string;
  pageDescription?: string;
  /** Undefined will render a default image. Empty string will render no image. */
  pageImage?: string;
};

export default function HeaderMeta(props: HeaderMetaProps): ReactElement {
  let pageTitle = props.pageTitle.trim();
  if (pageTitle) {
    pageTitle = `${pageTitle} - Foxglove`;
  } else {
    pageTitle = "Foxglove";
  }

  const pageDescription = props.pageDescription?.trim();

  let pageImage: string | undefined;
  if (props.pageImage === "") {
    // empty string renders no image
    pageImage = undefined;
  } else if (props.pageImage == undefined) {
    pageImage = `${BASE_URL}/images/og-image.jpeg`;
  } else {
    pageImage = `${BASE_URL}${props.pageImage}`;
  }

  const router = useRouter();

  return (
    <Head>
      <title>{pageTitle}</title>
      {pageDescription && <meta name="description" content={pageDescription} />}
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />

      {/*
        Link previews for social media
        https://ogp.me
        https://medium.com/slack-developer-blog/everything-you-ever-wanted-to-know-about-unfurling-but-were-afraid-to-ask-or-how-to-make-your-e64b4bb9254
        Note: Twitter requires an absolute URL for og:image.
        */}
      <meta property="og:title" content={pageTitle} />
      {pageDescription && <meta property="og:description" content={pageDescription} />}
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Foxglove" />
      {pageImage && <meta property="og:image" content={pageImage} />}
      <meta property="og:url" content={`${BASE_URL}${router.asPath}`} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@foxglove" />
    </Head>
  );
}
