import React, { ReactElement, useState } from "react";

import { submitForm, HubspotResponse, HubspotLegalConsentOptions } from "../lib/hubspot";

type Props = {
  className?: string;
  btnStyle?: "light" | "dark";
};

const DEFAULT_BTN_TEXT = "Subscribe";
const PROGRESS_BTN_TEXT = "Submitting...";
const SUCCESS_BTN_TEXT = "Subscribed!";

const SIGNUP_FORM_ID = "5b8efa65-827e-46ce-8021-9b1de3061bf1";
const SUBSCRIPTION_TYPE_ID = 38017915;
const legalConsentOptions = {
  legitimateInterest: {
    value: true,
    subscriptionTypeId: SUBSCRIPTION_TYPE_ID,
    legalBasis: "LEAD",
    text: "Foxglove needs the information you provide to contact you about our products and services. You may unsubscribe from these communications at any time. For more information, please see our Privacy Policy.",
  },
} as HubspotLegalConsentOptions;

export default function NewsletterForm({ className, btnStyle }: Props): ReactElement {
  const btnClassNames =
    btnStyle === "light"
      ? "text-color-primary hover:text-color-primary-hov bg-white"
      : "text-white bg-gradient-to-r from-color-secondary to-color-primary hover:from-color-secondary-hov hover:to-color-primary-hov";

  const [email, setEmail] = useState("");
  const [buttonText, setButtonText] = useState(DEFAULT_BTN_TEXT);
  const [errorText, setErrorText] = useState("");

  return (
    <>
      <form
        className={`mt-4 sm:flex ${className ?? ""}`}
        method="post"
        target="_blank"
        onSubmit={(e) => {
          e.preventDefault();
          setButtonText(PROGRESS_BTN_TEXT);

          submitForm(SIGNUP_FORM_ID, {
            fields: [{ objectTypeId: "0-1", name: "email", value: email }],
            context: { pageUri: window.location.href },
            legalConsentOptions,
          }).then(
            (res: HubspotResponse) => {
              if (res.errors) {
                setErrorText(
                  `Newsletter signup failed: ${res.errors.map((err) => err.message).join(`. `)}.`,
                );
                setButtonText(DEFAULT_BTN_TEXT);
              } else {
                setEmail("");
                setErrorText("");
                setButtonText(SUCCESS_BTN_TEXT);
              }
            },
            (error) => {
              setErrorText(`Newsletter signup failed: ${(error as string) ?? ""}`);
              setButtonText(DEFAULT_BTN_TEXT);
            },
          );
        }}
      >
        <label htmlFor="emailAddress" className="sr-only">
          Email address
        </label>
        <input
          type="email"
          name="member[email]"
          id="emailAddress"
          autoComplete="email"
          required
          className="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:placeholder-gray-400"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
          <button
            type="submit"
            className={`w-full flex items-center justify-center px-4 py-2 sm:py-3 border border-transparent text-base font-medium rounded-md shadow-sm ${btnClassNames}`}
          >
            {buttonText}
          </button>
        </div>
      </form>
      {errorText ? <p className="text-indigo-700 italic mt-4">{errorText}</p> : null}
    </>
  );
}
