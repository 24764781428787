import { ChevronDownIcon as SmallSolidChevronDownIcon } from "@heroicons/react/20/solid";
import Link from "next/link";
import React, { ReactElement, useState } from "react";

import styles from "./Header.module.css";
import { useAnalytics, AppEvent, EventProperties } from "../hooks/useAnalytics";

export type HeaderItemProps = {
  title: string;
  href: string;
  target?: "_blank";
  event?: AppEvent;
  eventProperties?: EventProperties;
  type: "link" | "button";
  submenu?: HeaderSubItem[];
};

export type HeaderSubItem = {
  title: string;
  href: string;
  target?: string;
  icon: typeof SmallSolidChevronDownIcon;
};

export default function HeaderItem(props: HeaderItemProps): ReactElement {
  const analytics = useAnalytics();
  const [isOpen, setIsOpen] = useState(false);

  if (!props.submenu || props.submenu.length < 1) {
    return (
      <Link
        href={props.href}
        className={props.type === "link" ? styles.headerNavLink : styles.headerNavButton}
        target={props.target}
        onClick={() => {
          if (props.event) {
            analytics.trackEvent(props.event, props.eventProperties);
          }
        }}
      >
        {props.title}
      </Link>
    );
  }

  return (
    <div className={`${styles.navLink ?? ""} relative text-white hover:text-gray-300`}>
      <button
        type="button"
        className="group inline-flex items-center font-medium focus:outline-none"
        aria-expanded="false"
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <span>{props.title}</span>
        <SmallSolidChevronDownIcon className="ml-2 h-5 w-5" />
      </button>

      {isOpen ? (
        <div
          className="absolute z-10 left-1/2 transform -translate-x-1/2 pt-3 px-2 w-max max-w-sm sm:px-0"
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="relative grid gap-6 bg-white px-5 py-5 sm:gap-8">
              {props.submenu.map((subitem, i) => {
                return (
                  <Link
                    key={i}
                    href={subitem.href}
                    target={subitem.target}
                    className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                  >
                    <subitem.icon className="flex-shrink-0 h-6 w-6 text-indigo-600" />
                    <div className="ml-4">
                      <p className="font-medium text-gray-900">{subitem.title}</p>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
