import * as Icon from "@heroicons/react/24/outline";
import { pick } from "lodash";
import Link from "next/link";
import React, { ReactElement, useEffect } from "react";

import styles from "./Header.module.css";
import HeaderItem, { HeaderItemProps } from "./HeaderItem";
import HeaderMeta, { HeaderMetaProps } from "./HeaderMeta";
import { AppEvent, useAnalytics } from "../hooks/useAnalytics";
import useToggle from "../hooks/useToggle";

type Props = HeaderMetaProps & {
  shadow?: boolean;
};

const rightMenuItems: HeaderItemProps[] = [
  { title: "Docs", href: "https://docs.foxglove.dev", type: "link" },
  { title: "Download", href: "https://foxglove.dev/download", type: "link" },
  {
    title: "Sign in",
    href: "https://app.foxglove.dev/signin",
    event: AppEvent.HEADER_SIGN_IN_CLICKED,
    type: "link",
  },
  {
    title: "Get started",
    href: "https://app.foxglove.dev/signup",
    event: AppEvent.HEADER_GET_STARTED_CLICKED,
    eventProperties: { header: true },
    type: "button",
  },
];

export default function Header(props: Props): ReactElement {
  const analytics = useAnalytics();
  const [mobileNavOn, toggleMobileNav] = useToggle();

  const leftMenuItems: HeaderItemProps[] = [
    { title: "Product", href: "/product", type: "link" },
    { title: "Blog", href: "/blog", type: "link" },
    { title: "Customers", href: "/customers", type: "link" },
    { title: "Examples", href: "/examples", type: "link" },
    { title: "Pricing", href: "/pricing", type: "link" },
  ];

  // prevent document body from scrolling while mobile nav menu is open
  useEffect(() => {
    document.body.style.overflow = mobileNavOn ? "hidden" : "";
  }, [mobileNavOn]);

  // shadow is enabled by default
  const shadow = props.shadow ?? true ? "shadow" : undefined;

  return (
    <>
      <HeaderMeta {...pick(props, ["pageTitle", "pageDescription", "pageImage"])} />
      <header className={[styles.header, shadow].join(" ")}>
        <div className={styles.headerInner}>
          <div className={styles.headerLogo}>
            <Link href="/">
              <img src="/images/logo-white.svg" alt="Foxglove" />
            </Link>
          </div>
          <nav className={styles.headerNav}>
            {leftMenuItems.map((item, i) => (
              <HeaderItem key={i} {...item} />
            ))}
          </nav>
          <nav className={styles.headerNavRight}>
            {rightMenuItems.map((item, i) => (
              <HeaderItem key={i} {...item} />
            ))}
          </nav>

          <div className={styles.headerHamburger}>
            <button type="button" aria-expanded="false" onClick={toggleMobileNav}>
              <span className="sr-only">Open menu</span>
              {mobileNavOn ? <Icon.XMarkIcon /> : <Icon.Bars3Icon />}
            </button>
          </div>
        </div>
      </header>
      <nav
        className={[styles.mobileNav, mobileNavOn ? undefined : styles.mobileNavHidden].join(" ")}
      >
        <div className={styles.mobileNavInner}>
          {/* mobile menu items */}
          {[...leftMenuItems, ...rightMenuItems].map((item, i) => {
            if (item.submenu) {
              return (
                <div key={i} className={styles.mobileNavGroup}>
                  <div className={styles.mobileNavGroupTitle}>{item.title}</div>
                  {item.submenu.map((subitem, j) => (
                    <Link
                      key={`${i}.${j}`}
                      href={subitem.href}
                      className={styles.mobileNavLink}
                      onClick={toggleMobileNav}
                    >
                      {subitem.title}
                    </Link>
                  ))}
                </div>
              );
            }

            return (
              <Link
                key={i}
                href={item.href}
                className={item.type === "link" ? styles.mobileNavLink : styles.mobileNavButton}
                onClick={() => {
                  toggleMobileNav();
                  if (item.event) {
                    analytics.trackEvent(item.event, item.eventProperties);
                  }
                }}
              >
                {item.title}
              </Link>
            );
          })}
        </div>
      </nav>
    </>
  );
}
