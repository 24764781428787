import { sendGTMEvent } from "@next/third-parties/google";
import { CaptureOptions } from "posthog-js";
import { usePostHog } from "posthog-js/react";

export enum AppEvent {
  CONTACT_FORM_ERROR = "Contact Form Error",
  CONTACT_FORM_SUBMITTED = "Contact Form Submitted",
  DOWNLOAD_PAGE_DOWNLOAD_CLICKED = "Download Page Download Clicked",
  GENERIC_GET_STARTED_CLICKED = "Generic Get Started Clicked",
  HEADER_GET_STARTED_CLICKED = "Header Get Started Clicked",
  HEADER_SIGN_IN_CLICKED = "Header Sign In Clicked",
  HOMEPAGE_DOWNLOAD_CLICKED = "Homepage Download Clicked",
  HOMEPAGE_GET_DEMO_CLICKED = "Homepage Get Demo Clicked",
  HOMEPAGE_GET_STARTED_CLICKED = "Homepage Get Started Clicked",
  EXAMPLE_CARD_CLICKED = "Example Card Clicked",
}

type PropertyValue = string | boolean | Record<string, string> | undefined;

export type EventProperties = Record<string, PropertyValue>;

interface IAnalytics {
  trackEvent: (
    name: AppEvent,
    properties?: Record<string, PropertyValue>,
    options?: CaptureOptions,
  ) => void;
  getFeatureFlag(key: string): boolean | string | undefined;
  isFeatureEnabled(key: string): boolean | undefined;
  onFeatureFlags(callback: () => void): () => void;
}

/**
 * Returns a function to track analytics events
 */
export function useAnalytics(): IAnalytics {
  const posthog = usePostHog();

  return {
    trackEvent: (name: AppEvent, properties?: EventProperties, options?: CaptureOptions) => {
      const eventName = `Website: ${name}`;
      posthog?.capture(eventName, properties, options);
      sendGTMEvent({ event: name, ...properties });
    },
    getFeatureFlag: (key: string) => posthog?.getFeatureFlag(key),
    isFeatureEnabled: (key: string) => posthog?.isFeatureEnabled(key),
    onFeatureFlags: (callback: () => void) => posthog?.onFeatureFlags(callback),
  };
}
